<template>

  <v-card :loading="loaders.loading" flat>

    <v-row dense>
      <v-col  v-if="user_id == idprofile && chapterll.listEntity.length > 0"
        :key="'lnew'"
        class="d-flex child-flex v-alert--border"
        cols="4"
      >
        <v-sheet @click="dialog.newchapter = true; e1 = 1; chapter = {id:null}"
                 elevation="0"
                 max-width="600"
                 rounded="lg"
                 width="100%"
                 class="pa-4 text-center mx-auto"
        >
          <v-icon
            class="mb-5"
            color="info"
            size="112"
          >{{ icons.mdiPlus }}
          </v-icon>

          <h2 class="text-h5 mb-6">Nouveau One-Shot</h2>

        </v-sheet>
      </v-col>
      <v-col
        v-for="(item, index) in chapterll.listEntity"
        :key="index"
        class="d-flex child-flex"
        cols="4"
      >
        <v-card>
          <v-card-title class="justify-space-between">
            <v-btn small :to="'/scan/one-shot/'+item.id">
              Lire
            </v-btn>
            <v-spacer></v-spacer>
            <template v-if="user_id == idprofile">
              <v-btn class="mr-3" icon @click="chapter = item; dialog.newchapter = true">
                <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
              </v-btn>
              <v-btn icon @click="deleteChap(item, index)">
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </template>
          </v-card-title>
          <v-card-subtitle>{{ item.title }}</v-card-subtitle>
          <v-img
            class="white--text align-end"
            :src="item.cover_src"
            :lazy-src="require('@/assets/images/holder.jpg')"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0 "
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
      </v-col>
    </v-row>

    <v-sheet
      v-if="loaders.loading"
      class="mx-auto"
    >
      <v-row dense>
        <v-col
          v-for="n in 9"
          :key="'lnew'+n"
          class="d-flex child-flex"
          cols="4"
        >
          <v-card
            :color="`grey darken-2 `"
            class="pa-3"
          >
            <v-skeleton-loader
              class="mx-auto"
              :height="120"
              :width="120"
              type="card"
            ></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>

    <!--
        <v-card v-intersect="infiniteScrolling"></v-card>-->

      <v-sheet v-if="!loaders.loading && chapterll.listEntity.length === 0"
               elevation="0"
               max-width="600"
               rounded="lg"
               width="100%"
               class="pa-4 text-center mx-auto"
      >
        <v-icon
          class="mb-5"
          color="warning"
          size="112"
        >{{ icons.mdiExclamation }}
        </v-icon>

        <h2 class="text-h5 mb-6">Aucun element trouve</h2>

        <template v-if="user_id == idprofile" >
          <p class="mb-4 text-medium-emphasis text-body-2">
            Vous pouvez creer vos propre series et one-shot
          </p>

          <v-divider class="mb-4"></v-divider>

          <div class="text-center">
            <v-btn
              class="text-none"
              color="success"
              rounded
              variant="flat" @click="dialog.newchapter = true; chapter = {id:null}"
            >
              Ajouter un One-shot
            </v-btn>
          </div>
        </template>

      </v-sheet>

    <template v-if="user_id == idprofile" >

      <v-dialog fullscreen v-model="dialog.newchapter">

        <v-card flat>

          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog.newchapter = false; e1 = 1">
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step
                step="1"
                @click="e1 = 1"
              >
                Informations Detaillees
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                @click="e1 = 2"
              >
                Charger les Images (max 0.9Mo/images)
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items class="pa-0 pa-md-3 pa-lg-6">
              <v-stepper-content class="pa-0 pa-md-3 pa-lg-6" step="1">

                <v-card-text>
                  <v-text-field outlined v-model="chapter.title" label="Titre"></v-text-field>
                  <v-textarea outlined v-model="chapter.synopsis" label="Synopsis"></v-textarea>

                </v-card-text>

                <v-card-actions>
                  <v-btn color="primary" :loading="loaders.update" @click="save">
                    Enregistrer
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn v-if="chapter.id" color="primary" :loading="loaders.update" @click="e1 = 2">
                    Charger les pages
                  </v-btn>
                </v-card-actions>

              </v-stepper-content>
              <v-stepper-content class="pa-0 pa-md-3 pa-lg-6" step="2">

                <v-card-text class="pa-0 pa-md-3 pa-lg-6">
                  <v-alert
                    color="blue"
                    type="warning"
                  >
                    Dimension pour les pages la largeur doit etre comprise entre 800px et 1200px
                    pour les doubles pages entre 1500 et 1800 pour un chargement optimal
                  </v-alert>

                  <chapter-form-component
                    v-if="chapter.id"
                    :chapter="chapter"
                    @changeCover="(idcover, nc) => chapter = nc"
                  ></chapter-form-component>

                </v-card-text>

                <v-card-actions>
                  <v-btn color="primary" :loading="loaders.update" @click="e1 = 1">
                    precedent
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>

              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

          <v-card-actions class="mt-6 pb-16" v-if="chapter.id">
            <v-switch
              v-model="chapter.status"
              hide-details
              value="1"
              true-value="1"
              false-value=""
              :label="` ${chapter.status == 1 ? 'Public' : 'Brouillon'}`"
            ></v-switch>
            <v-spacer></v-spacer>
            <v-btn color="primary" :loading="loaders.update" @click="save(1)">
              Publier
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>

    </template>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiVuetify,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiSquareEditOutline,
  mdiEyeOffOutline,
  mdiChevronLeft,
  mdiDotsHorizontal,
  mdiExclamation,
  mdiPlus,
} from '@mdi/js'
import {ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import FollowToggleComponent from '@/components/FollowToggleComponent.vue'
import ProfileItem from "@/components/ProfileItem.vue";
import ChapterFormComponent from "@/components/ChapterFormComponent.vue";

export default {
  components: {
    ChapterFormComponent,
    ProfileItem,
    FollowToggleComponent,
  },
  props: {
    chapteroption: Object,
    idprofile: Number,
    user_id: String,
    user: Object,
  },
  setup(props) {
    const dialog = ref({
      newchapter: false,
      imageviewer: false,
      authentication: false,
    })

    const loaders = ref({
      config: false,
      loading: false,
      retry: false,
    })

    const chapterll = ref({})
    const chapter = ref({id: null})
    const e1 = ref(1)

    const save = (publish) => {

      loaders.value.loading = true
      let url;
      if (chapter.value.id)
        url = 'update.chapter?id=' + chapter.value.id
      else
        url = 'chapter.create'

      Drequest.api(url)
        .data(
          {
            chapter: {
              'user.id': props.user.id,
              'title': chapter.value.title,
              status: chapter.value.status,
              //'tree_item\\category.id': post.value.category.id,
              'synopsis': chapter.value.synopsis //$("#postcontent").html(),
            }
          })
        .raw((response) => {

          loaders.value.loading = false
          if (response.success) {
            if (url === 'chapter.create') {
              chapter.value.id = response.chapter.id
              e1.value = 2
              chapterll.value.listEntity.push(response.chapter)
            } else if (publish) {
              chapter.value = {}
              dialog.value.newchapter = false
              e1.value = 1
            }
          }

        })

    }
    const loadchapter = next => {

      loaders.value.loading = true
      loaders.value.retry = false


      Drequest.api('lazyloading.chapter?dfilters=on&dsort=id desc')
        .param({
          'user.id:eq': props.idprofile,
          per_page: 12,
          next,
          user_id: props.user_id,
          ...props.chapteroption,
        })
        .get(response => {
          console.log(response)
          loaders.value.loading = false
          if (next >= 2) {

            chapterll.value.listEntity = [...chapterll.value.listEntity, ...response.listEntity]
            chapterll.value.next = response.next

          } else {
            loaders.value.loading = false
            chapterll.value = response

          }
        })
        .fail(e => {
          loaders.value.loading = false
          loaders.value.retry = true
          console.log(e)
        })

    }
    loadchapter(1)

    const infiniteScrolling = (entries, observer, isIntersecting) => {
      console.log(loaders.value.loading)

      if (loaders.value.loading) return

      console.log(chapterll.value.next >= 2, chapterll.value)
      // chapterll.value.next >= 2 &&
      if (chapterll.value.remain) loadchapter(chapterll.value.next)

    }

    const deleteChap = (chapter, index) => {
      console.log(loaders.value.loading)


    }

    return {
      infiniteScrolling,
      deleteChap,
      save,

      chapterll,
      loaders,
      dialog,
      chapter,
      e1,

      icons: {
        mdiClose,
        mdiExclamation,
        mdiVuetify,
        mdiSquareEditOutline,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
        mdiDotsHorizontal,
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.av {
  border: 5px solid var(--v-secondary-base);
}

.av-active {
  border: 5px solid var(--v-primary-base);
}

.v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
  z-index: 120 !important;
}

/*.v-dialog__content {
  z-index: 4001 !important;
}*/
</style>
