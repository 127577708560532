<template>

  <v-card :loading="loaders.loading" flat>


    <v-row dense>
      <v-col
        v-for="(post, index) in postll.listEntity"
        :key="index"
        class="d-flex child-flex"
        cols="4" v-if="post.postimages[0]"
      >
        <post-minified-component
          :key="post.id"
          :index="index"
          :post-item="post"
          :postimage="post.postimages[0]"
          :user="user"
          :limit="3"
        >
        </post-minified-component>
      </v-col>
    </v-row>

    <v-sheet
      v-if="loaders.loading"
      class="mx-auto"
    >
      <v-row dense>
        <v-col
          v-for="n in 9"
          :key="'lnew'+n"
          class="d-flex child-flex"
          cols="4"
        >
          <v-card
            :color="`grey darken-2 `"
            class="pa-3"
          >
            <v-skeleton-loader
              class="mx-auto"
              :height="120"
              :width="120"
              type="card"
            ></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>

<!--

    <v-card v-intersect="infiniteScrolling"></v-card>
-->

    <v-sheet v-if="!loaders.loading && postll.listEntity.length === 0"
      elevation="0"
      max-width="600"
      rounded="lg"
      width="100%"
      class="pa-4 text-center mx-auto"
    >
      <v-icon
        class="mb-5"
        color="warning"
        size="112"
      >{{icons.mdiExclamation}}</v-icon>

      <h2 class="text-h5 mb-6">Aucun element trouve</h2>

<!--      <p class="mb-4 text-medium-emphasis text-body-2">
        To see a report on this reconciliation, click <a href="#"
                                                         class="text-decoration-none text-info">View
        reconciliation report.</a>

        <br>

        Otherwise, you're done!
      </p>

      <v-divider class="mb-4"></v-divider>

      <div class="text-center">
        <v-btn
          class="text-none"
          color="success"
          rounded
          variant="flat"
          width="90"
        >
          Done
        </v-btn>
      </div>-->
    </v-sheet>

  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiVuetify,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiSquareEditOutline,
  mdiEyeOffOutline,
  mdiChevronLeft,
  mdiDotsHorizontal,
  mdiExclamation,
} from '@mdi/js'
import {ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import PostMinifiedComponent from '@/components/PostMinifiedComponent'
import FollowToggleComponent from '@/components/FollowToggleComponent.vue'
import ProfileItem from "@/components/ProfileItem.vue";

export default {
  components: {
    ProfileItem,
    FollowToggleComponent,
    PostMinifiedComponent,
  },
  props: {
    postoption: Object,
    idprofile: Number,
    user_id: String,
    user: Object,
  },
  setup(props) {
    const dialog = ref({
      newpost: false,
      imageviewer: false,
      authentication: false,
    })

    const loaders = ref({
      config: false,
      loading: false,
      retry: false,
    })

    const postll = ref({})

    const loadPost = next => {

      loaders.value.loading = true
      loaders.value.retry = false


      Drequest.api('lazyloading.post?dfilters=on&dsort=id desc')
        .param({
          'user.id:eq': props.idprofile,
          per_page: 12,
          next,
          user_id: props.user_id,
          ...props.postoption,
        })
        .get(response => {
          console.log(response)
          loaders.value.loading = false
          if (next >= 2) {

            postll.value.listEntity = [...postll.value.listEntity, ...response.listEntity]
            postll.value.next = response.next

          } else {
            loaders.value.loading = false
            postll.value = response

          }
        })
        .fail(e => {
          loaders.value.loading = false
          loaders.value.retry = true
          console.log(e)
        })

    }
    loadPost(1)

    const infiniteScrolling = (entries, observer, isIntersecting) => {
      console.log(loaders.value.loading)

      if (loaders.value.loading) return

      console.log(postll.value.next >= 2, postll.value)
      // postll.value.next >= 2 &&
      if (postll.value.remain) loadPost(postll.value.next)

    }

    return {
      infiniteScrolling,

      postll,
      loaders,

      icons: {
        mdiClose,
        mdiExclamation,
        mdiVuetify,
        mdiSquareEditOutline,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
        mdiDotsHorizontal,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.av {
  border: 5px solid var(--v-secondary-base);
}

.av-active {
  border: 5px solid var(--v-primary-base);
}

.v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
  z-index: 4000;
}
</style>
