<template>
  <v-card
    elevation="0"
    class="auth-card"
  >
    <v-card-title>
      Validation du compte
      <v-spacer></v-spacer>
      <v-btn
        text
        icon
        @click="$emit('close')"
      >
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-card-title>

    <v-card v-if="!confirm_account">
      <v-card-text>
        <p>Un code d'activation vous a ete envoye par SMS et/ou E-mail.</p>
        <v-otp-input
          v-model="activationcode"
          outlined
          dense
          :label="('Entrer le code d\'activation')"
          length="5"
          hide-details
          class="mb-3"
        ></v-otp-input>
        <v-text-field
          v-model="activationcode"
          :label="('Entrer le code d\'activation')"
          outlined
          dense
          counter="5"
          maxlenght="5"
          class="mb-3"
          :error-messages="server_response.error_message"
        ></v-text-field>
        <p>
          Vous n'avez pas recu de code?
          <v-btn
            :loading="server_response.loadregistration"
            small
            text
            @click="resendcode"
          >
            Renvoyer le code
          </v-btn>
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="server_response.loadregistration"
          block
          :loading="server_response.loadactivation"
          type="submit"
          small
          class="mt-3"
          color="primary"
          @click="validateAccount"
        >
          <v-icon
            style="color: white"
            size="20"
          >
            {{ icons.mdiAccountCircle }}
          </v-icon>
          Activer mon compte
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="registration_completed"
      persistent
      max-width="420"
    >
      <v-card class="text-center">
        <v-img
          :src="require('@/assets/images/pages/welcome.png')"
          max-height="210px"
          max-width="300px"
          alt="welcome"
          contain
          class="me-3 "
        ></v-img>

        <v-card-text class="text-center">
          <p>{{ server_response.detail }}</p><br><br>
          <h3>Truc et astuces</h3>
          <p>{{ server_response.astuce }}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="window.location.reload()"
          >
            Fermer
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            to="/subscription/new"
          >
            souscrire à un abonnement
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiClose,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import {
  required, emailValidator, selected, lengthValidator,
} from '@/plugins/utils/validation'

export default {
  props: {
    user: Object,
  },
  setup(props, { emit }) {
    const isPasswordVisible = ref(false)
    const confirm_account = ref(false)
    const registration_completed = ref(false)

    const valid = ref(false)
    const form = ref(null)

    const activationcode = ref('')
    const password = ref('')
    const loader = ref(false)
    const e1 = ref(1)
    const countries = ref([])
    const country = ref({})

    const server_response = ref({
      error: {},
      error_message: '',
      snack: false,
      alert: false,
      loadactivation: false,
      loadregistration: false,
    })

    const resendcode = () => {
      server_response.value.loadregistration = true
      Drequest.api(`resentactivationcode?user_id=${props.user.id}`)
        .get(response => {
          // server_response.value.snack = true
          alert(response.detail)
          server_response.value.loadregistration = false
        })
    }

    const validateAccount = () => {
      if (!activationcode.value) {
        alert('Le champs code activation est obligatoir!')

        return 0
      }
      server_response.value.loadactivation = true
      Drequest.api(`user.activateaccount?user_id=${props.user.id}`)
        .toFormdata({ activationcode: activationcode.value })
        .post(response => {
          console.log(response)
          server_response.value.loadactivation = false
          if (response.success) {
            confirm_account.value = false
            registration_completed.value = true
            Drequest.setUser({ ...props.user, is_activated: 1 })
            server_response.value.astuce = response.astuce
            server_response.value.detail = response.detail
          } else {
            alert(response.error)

            // server_response.value.error_message = response.detail
          }
        })
        .fail(e => {
          server_response.value.alert = true
          server_response.value.color = 'error'
          server_response.value.error_message = 'Oops. Nous n\'arrivons pas a nous connecter. Veuillez réessayer svp.' // response.detail
        })
    }

    return {
      validateAccount,
      resendcode,

      valid,
      form,
      isPasswordVisible,
      registration_completed,
      confirm_account,
      activationcode,
      password,
      loader,
      server_response,
      e1,
      countries,
      country,

      validators: {
        required,
        emailValidator,
        lengthValidator,
        selected,
      },
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
